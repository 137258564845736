import React from 'react'
// import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";

//leaflet
import Leaflet from '../../../components/leaflet';

//datepicker
import Datepickers from '../../../components/Datepicker';

//circularpro
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Card, Container, Row, Col, Dropdown, Navbar, Nav } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUser } from '@fortawesome/free-solid-svg-icons'

import '@lottiefiles/lottie-player'

// img
import product1 from '../../../assets/images/products/1.png'
import product2 from '../../../assets/images/products/2.jpg'
import product13 from '../../../assets/images/products/13.jpg'
import product4 from '../../../assets/images/products/4.jpg'
import user1 from '../../../assets/images/user/1.jpg'
import user5 from '../../../assets/images/user/5.jpg'
import user2 from '../../../assets/images/user/2.jpg'
import user3 from '../../../assets/images/user/3.jpg'
import { LottiePlayer } from '@lottiefiles/lottie-player';


const ClientDashbord = () => {



  const chart1 = {
    options: {

      colors: ['#1f1f7a', '#2e2eb8'],
      chart: {
        fontFamily: 'DM Sans',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: false,
          opacityFrom: .8,
          opacityTo: .2,
          stops: [0, 50, 100],
          colorStops: []
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        labels: {
          offsetY: 0,
          minWidth: 20,
          maxWidth: 20
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          minHeight: 20,
          maxHeight: 20
        },
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },
    series: [{
      name: 'Incomes',
      data: [90, 105, 72, 90, 65, 109, 130]
    }, {
      name: 'Expenses',
      data: [115, 93, 75, 102, 144, 52, 71]
    }]
  }


  const chart2 = {
    options: {

      colors: ['#b3cccc', '#04237D', '#4d4dff'],
      chart: {

        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          distributed: true,
          borderRadius: 5,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      grid: {
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },

      xaxis: {
        categories: [
          '30 Jan',
          '25 Feb',
          '28 Mar',
        ],
        labels: {
          minHeight: 20,
          maxHeight: 20,
          style: {
            fontSize: '12px'
          }
        }
      }
    },
    series: [{
      data: [55, 42, 19, 30, 20, 65, 21, 23, 45, 60, 30, 20]
    }]

  }
  const chart3 = {
    options: {

      chart: {
        height: 330,
        type: 'donut',

      },

      labels: ["Active Accounts", "Assets", "Pending Loans", "Agent Strength", 'Fy 2023,2024'],
      colors: ['#ffbb33', '#04237D', '#e60000', '#8080ff'],
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
          donut: {
            size: '80%',
            labels: {
              show: false,
              total: {
                show: false,

                color: '#BCC1C8',
                fontSize: '18px',
                fontFamily: 'DM Sans',
                fontWeight: 600,
              },
              value: {
                show: false,
                fontSize: '25px',
                fontFamily: 'DM Sans',
                fontWeight: 700,


              },
            }
          }
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        lineCap: 'round'
      },
      grid: {
        padding: {

          bottom: 0,
        }
      },
      legend: {
        position: 'bottom',
        offsetY: 8,
        show: true,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 268
          }
        }
      }]
    },
    series: [43, 58, 20, 35, 20]
  }
  return (
    <Container fluid>
      <Row>
        <Col md="12" className="mb-4 mt-1">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h4 className="font-weight-bold">Overview</h4>
            <div className="form-group mb-0 vanila-daterangepicker d-flex flex-row">
              <Navbar bg='white' data-bs-theme='dark'>
                <Container>

                  <Nav className='me-auto'>
                    <Nav.Link href='/' style={{ color: 'black', fontWeight: 'bold', fontSize: '24' }}>My Deposit</Nav.Link>
                    <Nav.Link href='/' style={{ color: 'black', fontWeight: 'bold', fontSize: '24' }}>Request Money</Nav.Link>
                    <Nav.Link href='/' style={{ color: 'black', fontWeight: 'bold', fontSize: '24' }}>Transfer</Nav.Link>
                  </Nav>
                </Container>
              </Navbar>
            </div>
          </div>
        </Col>
        <Col lg="12" md="8">
          <Row>
            <Col md='4' >
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h6 className="card-title">Account Number</h6>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">GS22175259</h5>
                        <lottie-player
                          style={{ width: '90px', marginLeft: '265px', }}
                          mode="normal"
                          src='https://lottie.host/896b7ddc-12f7-49fb-a16f-569d522c71ae/qQQTslNxUD.json'
                          autoplay cotrol loop
                        >
                        </lottie-player>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h6 className="card-title">AVAILABLE BALANCE</h6>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">INR 14400</h5>
                        <lottie-player
                          style={{ width: '50px', marginLeft: '250px' }}
                          mode='normal'
                          autoplay cotrol loop
                          src='https://lottie.host/537198d9-1c47-4ac1-8441-5ebd84edad69/mo84Ehl1Yf.json'
                        >
                        </lottie-player>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h6 className="card-title">Deposit</h6>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">1090</h5>
                        <lottie-player
                          style={{ width: '50px', marginLeft: '360px' }}
                          mode='normal'
                          autoplay cotrol loop
                          src='https://lottie.host/5e1e6bc5-f56d-4861-a62f-323a81a83ee4/ALF2PCPAf1.json'
                        >
                        </lottie-player>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h6 className="card-title">Transactions</h6>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">963</h5>
                        <lottie-player
                          style={{ width: '50px', marginLeft: '370px' }}
                          mode='normal'
                          autoplay cotrol loop
                          src='https://lottie.host/8b1f4b73-eba1-4bc6-8a16-2b96123c428b/ijcT5ePTQ5.json'
                        >
                        </lottie-player>

                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md='4' >
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h6 className="card-title">Loan</h6>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">20</h5>
                        <lottie-player
                          style={{ width: '50px', marginLeft: '350px' }}
                          mode='normal'
                          autoplay cotrol loop
                          src='https://lottie.host/5e1e6bc5-f56d-4861-a62f-323a81a83ee4/ALF2PCPAf1.json'
                        >
                        </lottie-player>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h6 className="card-title">DPS</h6>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">133</h5>
                        <lottie-player
                          style={{ width: '50px', marginLeft: '350px' }}
                          mode='normal'
                          autoplay cotrol loop
                          src='https://lottie.host/537198d9-1c47-4ac1-8441-5ebd84edad69/mo84Ehl1Yf.json'
                        >
                        </lottie-player>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h6 className="card-title">FDR</h6>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">81</h5>
                        <lottie-player
                          style={{ width: '50px', marginLeft: '350px' }}
                          mode='normal'
                          autoplay cotrol loop
                          src='https://lottie.host/c165b455-0b93-4c90-8371-b7560245f4ee/E3GWMGDjLD.json'
                        >
                        </lottie-player>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <h6 className="card-title">Withdraws</h6>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">476</h5>
                        <lottie-player
                          style={{ width: '50px', marginLeft: '350px' }}
                          mode='normal'
                          autoplay='true'
                          src='https://lottie.host/1c6d222c-f507-4265-977b-4829ae39e01b/MdTBnDnlOP.json'
                        >
                        </lottie-player>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>


            </Col>
          </Row>
        </Col>





        <Col lg="12" md="8">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Recent Transactions</h4>
              </div>
              <div className="card-header-toolbar d-flex align-items-center">
                <Dropdown>
                  <Dropdown.Toggle href="#" variant="text-muted pl-3" id="dropdownMenuButton-customer" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke="currentColor" strokeWidth="2" aria-hidden="true" focusable="false" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                      <g fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="1" />
                        <circle cx="19" cy="12" r="1" />
                        <circle cx="5" cy="12" r="1" /></g>
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu aria-labelledby="dropdownMenuButton-customer">
                    <Dropdown.Item href="#">
                      <svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                      <svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                      </svg>
                      View
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                      <svg className="svg-icon text-secondary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="table-color-heading">
                    <tr className="text-secondary">
                      <th scope="col" className='text-center'>Transactions</th>
                      <th scope="col" className='text-center'>Payment Type</th>
                      <th scope="col" className='text-center'>Details</th>
                      <th scope="col" className='text-center'>Paid In</th>
                      <th scope="col" className='text-center' >Paid Out</th>
                      <th scope="col" className='text-center'>Balance</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr className="white-space-no-wrap">
                      <td>902559</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>Digital Wallets</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>Amazon</div>
                        </div>
                      </td>
                      <td>
                        <p className="mb-0 text-success d-flex justify-content-start align-items-center">
                          <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                            <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                          200
                        </p>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>INR 104.98</div>
                        </div>
                      </td>
                      <td className='tex-f'>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">


                          </div>
                          <div>INR 104.98</div>
                        </div>
                      </td>
                    </tr>
                    <tr className="white-space-no-wrap">
                      <td>552149</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>UPI</div>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>Olx</div>
                        </div>
                      </td>
                      <td>

                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>INR 104.98</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>INR 104.98</div>
                        </div>
                      </td>
                    </tr>
                    <tr className="white-space-no-wrap">
                      <td>941529</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>Net Banking</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>Flipkart</div>
                        </div>
                      </td>
                      <td>
                        <p className="mb-0 text-success d-flex justify-content-start align-items-center">
                          <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                            <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                          2000
                        </p>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>INR 104.98</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-45 mr-2">

                          </div>
                          <div>INR 104.98</div>
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
                <div className="d-flex justify-content-end align-items-center border-top-table p-3">
                  <button className="btn btn-secondary btn-sm">See All</button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" md="12" className="col-lg-4 col-md-12">

        </Col>
      </Row>
    </Container>
  )
}

export default ClientDashbord;