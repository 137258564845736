import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Accordion, Button } from 'react-bootstrap'
import Scrollbar from 'smooth-scrollbar'
import { connect } from "react-redux";
import { getDarkMode } from '../../../../store/mode'

//img
import logo from '../../../../../src/assets/images/logo.png'

function mapStateToProps(state) {
    return {
        darkMode: getDarkMode(state)
    };
}


const minisidbar = () => {
    document.body.classList.toggle('sidebar-main')
}


const SidebarStyle = (props) => {

    //location
    let location = useLocation();

    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    var variant = '';
    if (sidebar !== null) {
        variant = '';
        switch (sidebar) {
            case "0":
                variant = 'sidebar-dark';
                break;
            case "1":
                variant = 'sidebar-light';
                break;
            default:
                variant = '';
                break;
        }
    }

    // Collapse state
    const [activeMenu, setActiveMenu] = useState(false)
    const [activesubMenu, setSubmenu] = useState(false)
    useEffect(
        () => {
            Scrollbar.init(document.querySelector('#my-scrollbar'))
        }
    )
    return (
        <>
            <div className={`iq-sidebar sidebar-default ${variant}`}>
                <div className="iq-sidebar-logo d-flex align-items-end justify-content-between">
                    <Link to="/" className="header-logo">
                        <img src={logo} style={{ height: '51px', marginBottom: '15px', marginTop: '5px' }} className={`img-fluid rounded-normal light-logo ${props.darkMode ? 'd-none' : ''}`} alt="logo" />

                    </Link>
                    <div className="side-menu-bt-sidebar-1">
                        <svg onClick={minisidbar} xmlns="http://www.w3.org/2000/svg" className="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className="data-scrollbar" data-scroll="1" id="my-scrollbar">
                    <nav className="iq-sidebar-menu">
                        <Accordion as="ul" id="iq-sidebar-toggle" className="side-menu" onSelect={(e) => setActiveMenu(e)}>
                            <li className={`${location.pathname === '/' ? '' : ''}  sidebar-layout`}>
                                <Link to="/" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Admin Dashboard</span>
                                    <p className="mb-0 w-10 badge badge-pill badge-primary">6</p>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/' ? '' : ''}  sidebar-layout`}>
                                <Link to="/ClientDashbord" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Client Dashboard</span>
                                    <p className="mb-0 w-10 badge badge-pill badge-primary">6</p>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/' ? '' : ''}  sidebar-layout`}>
                                <Link to="/AgentDashboard" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Agent Dashboard</span>
                                    <p className="mb-0 w-10 badge badge-pill badge-primary">6</p>
                                </Link>
                            </li>

                            <li className={`${location.pathname === '/customer' ? '' : ''}  sidebar-layout`}>
                                <Link to="/customer" className="svg-icon ">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Customer</span>
                                </Link>
                            </li>

                            <li className={`${location.pathname === '/branch-report' ? 'active' : ''}  sidebar-layout`} >
                                <Link to="/branch-report" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Branch Report</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/users-report' ? 'active' : ''}  sidebar-layout`} >
                                <Link to="/users-report" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Users Report</span>
                                </Link>
                            </li>

                            <li className={`${location.pathname === '/individual-report' ? 'active' : ''}  sidebar-layout`} >
                                <Link to="/individual-report" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19V6a1 1 0 011-1h5a1 1 0 011 1v13m-7 0h7m-7 0H5m7 0h2"></path>
                                        </svg>
                                    </i>
                                    <span className="ml-2">Individual Report</span>
                                </Link>
                            </li>




                            <li className={`${activeMenu === '0' ? 'active' : ''} sidebar-layout`}>
                                <Accordion.Toggle as={Button} href="#" eventKey="0" variant=" collapsed" data-toggle="collapse" aria-expanded={activeMenu === '0' ? 'true' : 'false'} >
                                    <i>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Authentication</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon iq-arrow-right arrow-active" width="15" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                    </svg>
                                </Accordion.Toggle>
                                <Accordion.Collapse className="submenu" eventKey="0">
                                    <ul className="submenu" data-parent="#iq-sidebar-toggle">
                                        <li className={`${location.pathname === '/auth/sign-in' ? 'active' : ''}  sidebar-layout`}>
                                            <Link to="/auth/sign-in" className="svg-icon">
                                                <i className=""><svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                                                </svg>
                                                </i><span className="">Login</span>
                                            </Link>
                                        </li>
                                        <li className={`${location.pathname === '/auth/sign-up' ? 'active' : ''}  sidebar-layout`}>
                                            <Link to="/auth/sign-up" className="svg-icon">
                                                <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                                                    </svg>
                                                </i><span className="">Register</span>
                                            </Link>
                                        </li>
                                        <li className={`${location.pathname === '/auth/recoverpw' ? 'active' : ''}  sidebar-layout`}>
                                            <Link to="/auth/recoverpw" className="svg-icon">
                                                <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                    </svg>
                                                </i><span className="">Reset Password</span>
                                            </Link>
                                        </li>
                                        <li className={`${location.pathname === '/auth/confirm-mail' ? 'active' : ''}  sidebar-layout`}>
                                            <Link to="/auth/confirm-mail" className="svg-icon">
                                                <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </i><span className="">Confirm Mail</span>
                                            </Link>
                                        </li>
                                        <li className={`${location.pathname === '/auth/lock-screen' ? 'active' : ''}  sidebar-layout`}>
                                            <Link to="/auth/lock-screen" className="svg-icon">
                                                <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                                    </svg>
                                                </i><span className="">Lock Screen</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </Accordion.Collapse>
                            </li>












                        </Accordion>
                    </nav>
                    <div className="pt-5 pb-5"></div>
                </div>
            </div>
        </>
    )
}

// export default SidebarStyle;
export default connect(mapStateToProps)(SidebarStyle)